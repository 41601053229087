<template>
  <div class="auth-wrapper">
    <div class="container">
      <div class="row auth-container">
        <div class="col-lg-6 col-md-12 auth-container-left">
          <a href="/">
            <img
              class="img-fluid"
              src="../../assets/images/logo/logo_register.svg"
            />
          </a>
        </div>
        <div class="col-lg-6 col-md-12 auth-container-right">
          <div class="auth-card">
            <div class="card-body">
              <be-form-error v-if="error" :message="error" />
              <h1>{{ menuTitle }}</h1>
              <form
                class="form"
                @submit.prevent="
                  haveToConfirm2Fa ? handle2FaConfirmation() : handleLoginForm()
                "
              >
                <template v-if="haveToConfirm2Fa">
                  <BaseInput
                    v-model="verifyField.code"
                    :placeholder="$t('auth.fields.code.placeholder')"
                    leftIconClass="icon-lock"
                    type="text"
                  />
                </template>
                <template v-else>
                  <BaseInput
                    v-model="credential.email"
                    :placeholder="$t('auth.fields.email.placeholder')"
                    leftIconClass="icon-user"
                    type="email"
                  />
                  <BaseInputPassword
                    v-model="credential.password"
                    :placeholder="$t('auth.fields.password.placeholder')"
                    leftIconClass="icon-lock"
                  />
                  <div class="form-group auth-checkbox">
                    <div
                      class="checkbox checkbox-sec checkbox-primary d-inline"
                    >
                      <input
                        id="checkbox-remember"
                        v-model="credential.remember"
                        type="checkbox"
                      />
                      <label class="cr" for="checkbox-remember">
                        {{ $t("auth.fields.remember_me") }}
                      </label>
                    </div>
                  </div>
                </template>
                <div class="form-group text-center">
                  <BaseButton
                    :disabled="disabledSubmitBtn"
                    :loading="loading"
                    :showLoading="true"
                    buttonClass="be-btn blue primary font-weight-bold w-100"
                  >
                    {{
                      $t(
                        `auth.${
                          haveToConfirm2Fa ? "verify_code" : "identify_me"
                        }`
                      )
                    }}
                  </BaseButton>
                  <div class="text-center f-14 mt-2" v-if="!haveToConfirm2Fa">
                    {{ $t("auth.new_on_bewallet") }}
                    <router-link :to="{ name: 'Register' }">
                      {{ $t("auth.register_you") }}
                    </router-link>
                  </div>
                  <div class="text-center">
                    <p class="mb-0 text-muted f-12">
                      <router-link
                        v-if="!haveToConfirm2Fa"
                        :to="{ name: 'PasswordReset' }"
                      >
                        {{ $t("auth.password_forgot") }}
                      </router-link>
                      <a
                        v-else
                        href="#"
                        @click.prevent="
                          verifyField.is_recovery = !verifyField.is_recovery
                        "
                      >
                        {{
                          $t(
                            `auth.${
                              verifyField.is_recovery
                                ? "use_recovery_code"
                                : "use_2fa_code"
                            }`
                          )
                        }}
                      </a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/layouts/Footer";
import { required, email } from "vuelidate/lib/validators";
import { recaptchaMixin } from "@/mixins/recaptchaMixin";
import BeFormError from "@/components/common/BeFormError";
import { mapState } from "vuex";

export default {
  name: "Login",
  mixins: [recaptchaMixin],
  components: { Footer, BeFormError },

  data() {
    return {
      loading: false,
      verifyField: {
        code: null,
        is_recovery: false,
      },
      credential: {
        email: null,
        password: null,
        remember: false,
        recaptcha: null,
        attempt: 3,
        web: true,
      },
      error: null,
    };
  },

  computed: {
    ...mapState("auth", ["haveToConfirm2Fa"]),

    disabledSubmitBtn() {
      return this.loading || this.recaptchaToken === null;
    },
    menuTitle() {
      return this.haveToConfirm2Fa
        ? this.verifyField.is_recovery
          ? this.$t("auth.menu.verify_recovery")
          : this.$t("auth.menu.verify_2fa")
        : this.$t("auth.menu.identify");
    },
    userRedirectedRoute() {
      return this.$route.query.redirect || { name: "dashboard" };
    },
  },

  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`),
    };
  },

  mounted() {
    if (this.$route.query.email)
      this.credential.email = decodeURI(this.$route.query.email);
  },

  methods: {
    handle2FaConfirmation() {
      if (this.verifyField.code) {
        this.error = null;
        this.loading = true;

        this.$store
          .dispatch("auth/verifyMFACode", this.verifyField)
          .then(() => {
            this.$router.push(this.userRedirectedRoute);
          })
          .catch(err => {
            this.loadRecaptcha();
            if (err) {
              if (err.msg) this.error = err.msg;
              else this.error = err.response?.data?.message;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleLoginForm() {
      if (!this.$v.invalid) {
        this.error = null;
        this.loading = true;
        if (this.credential.attempt) {
          this.credential.recaptcha = this.recaptchaToken;
        }

        this.$store
          .dispatch("auth/login", this.credential)
          .then(({ user }) => {
            if (user.is_2fa_enabled) this.haveToConfirm2Fa = true;
            else this.$router.push(this.userRedirectedRoute);
          })
          .catch(err => {
            this.loadRecaptcha();
            if (err) {
              if (err.msg) this.error = err.msg;
              else this.error = err.response?.data?.message;
            }
            this.loading = false;
          });
      }
    },
  },
  validations: {
    credential: {
      email: { required, email },
      password: { required },
    },
  },
};
</script>

<style scoped></style>
